import { Component } from "react";
import HowItWorksCard from "./HowItWorksCard/HowItWorksCard";

class HowItWorks extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <div
        className="container"
        id="how-it-works"
        style={{ marginTop: "24px" }}
      >
        <p class="h2 text-center firasans">How it works?</p>
        <hr />
        <div class="card-deck">
          <HowItWorksCard
            title="Personally Crafted"
            image="images/bussiness-man.png"
            information={[
              "Custom designed only by Jay",
              "No standard itenaries",
              "International / Domestic",
            ]}
          ></HowItWorksCard>
          <HowItWorksCard
            title="Transparent Pricing"
            image="images/rupee.png"
            information={[
              "No deals/offers or discounts",
              "Better than online deals",
              "Flexible payout options",
            ]}
          ></HowItWorksCard>
          <HowItWorksCard
            title="Technology Free"
            image="images/banned.png"
            information={[
              "Nothing more than email and personalised calls",
              "No pesky apps",
              "No AI/ML chatbots",
              "No call centres",
            ]}
          ></HowItWorksCard>
          <HowItWorksCard
            title="One Stop Solution"
            image="images/solution.png"
            information={[
              "Flights, Charters, Yachts, Helicopters*",
              "Hotels & Sights",
              "Adventure & off beat",
              "Birthdays, Bachelorettes, Celebrations, reunions and luxury outings",
            ]}
          ></HowItWorksCard>
        </div>
      </div>
    );
  }
}

export default HowItWorks;
