import React from "react";
import "./OurTeamCard.css";

// import SimpleMap from './Components/SimpleMap';

function OurTeamCard(props) {
  return (
    <div className="our-team card mukta">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="picture">
          <img className="img-fluid" src={props.image} alt=""></img>
        </div>
      </div>
      <div className="team-content">
        <h3 className="name">{props.name}</h3>
        <h4 className="title">{props.title}</h4>
      </div>
      <div className="pl-4 pr-4 pt-4">{props.desc}</div>
    </div>
  );
}

export default OurTeamCard;
