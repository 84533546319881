import React, { useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link as RouterLink } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";
import "./Topbar.css";

class Topbar extends React.Component {
  componentDidMount() {
    let navbar = document.querySelector(".navbar");
    console.log(this.props.isHome);

    if (this.props.isHome == "true") {
      window.addEventListener("scroll", (e) => {
        if (window.scrollY > 10) {
          navbar.classList.add("active");
          // tedlogonav.style.visibility = "visible";
        } else {
          navbar.classList.remove("active");
          // tedlogonav.style.visibility = "hidden";
        }
      });
    } else {
      navbar.classList.add("active");
      // tedlogonav.style.visibility = "visible";
    }
  }

  render() {
    return (
      <Navbar
        expand="lg"
        className="fixed-top scrolling navbar-trans navbar-expand-lg navbar-inverse"
        variant="dark"
      >
        <Container>
          <NavLink to="/#home">
            <Navbar.Brand>
              {/* <img
                                id="tedlogonav"
                                alt=""
                                src={window.location.origin + '/images/tedxlogo.png'}
                                width="189.56px"
                                height="42.07px"
                                className="d-inline-block align-top"
                            /> */}
              <span
                className="d-inline-block align-top text-white"
                style={{ "font-family": "'Open Sans', sans-serif" }}
              >
                <strong>
                  leisure <span style={{ color: "#808080" }}>vacay</span>
                </strong>
              </span>
            </Navbar.Brand>
          </NavLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link className="underline">
                <NavLink
                  to="/#home"
                  activeClassName="active"
                  className="nav-link"
                >
                  Home
                </NavLink>
              </Nav.Link>
              <Nav.Link className="underline">
                <NavLink
                  to="/#how-it-works"
                  activeClassName="active"
                  className="nav-link"
                >
                  How it works?
                </NavLink>
              </Nav.Link>
              <Nav.Link className="underline">
                <NavLink
                  to="/#our-team"
                  activeClassName="active"
                  className="nav-link"
                >
                  Our Team
                </NavLink>
              </Nav.Link>
              <Nav.Link className="underline">
                <NavLink
                  to="/#contact-us"
                  activeClassName="active"
                  className="nav-link"
                >
                  Contact Us
                </NavLink>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Topbar;
