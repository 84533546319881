import React from "react";
import "./Footer.css";
// import SimpleMap from './Components/SimpleMap';

function Footer() {
  return (
    <div id="contact-us" className="row wrapper__footer">
      <div className="col-md-4 middle firasans">
        <div>
          <h3
            style={{
              paddingLeft: "18px",
              "font-family": "'Open Sans', sans-serif",
            }}
          >
            leisure <span style={{ color: "#808080" }}>vacay</span>
          </h3>
          {/* <i className="fa fa-map-marker"></i>
          <p>
            <span> Symposium VIIT, Srv No. 3/4, Kondhwa Budruk</span> Pune –
            411048, Maharashtra (India)
          </p> */}
        </div>
        <div>
          <i className="fa fa-phone"></i>
          <p>
            <a href="tel:+917770084767"> (+91) 77700 84767</a>
          </p>
        </div>
        <div>
          <i className="fa fa-envelope"></i>
          <p>
            <a href="mailto:jay@leisurevacay.com"> jay@leisurevacay.com</a>
          </p>
        </div>
        <div>
          <i className="fa fa-whatsapp"></i>
          <p>
            <a href="https://wa.me/917770084767?text=Hello!%20I%20want%20to%20enquire%20about%20Leisure%20Vacay">
              Whatsapp Us!
            </a>
          </p>
        </div>
      </div>
      <div className="col-md-6 right">
        <h4 className="firasans">Travel is personal!</h4>
        <div className="mukta">
          <p>
            Plotting your escape is as much an experience as the journey itself.
          </p>
          <p>
            Our first conversation will be your glimpse into the multiverse of
            Leisure Vacay's experience choices.
          </p>
          <p>
            Before any of that, write the first sketch of your dream escape at{" "}
            <a href="mailto:jay@leisurevacay.com">jay@leisurevacay.com</a>
          </p>
        </div>
      </div>
    </div>
    // <div className="row__footer wrapper__footer">
    //   {/* <div className="column__footer left"><SimpleMap //></div> */}

    //   <div className="column__footer left">
    //     <div>
    //       <i className="fa fa-map-marker"></i>
    //       <p>
    //         <span> Symposium VIIT, Srv No. 3/4, Kondhwa Budruk</span> Pune –
    //         411048, Maharashtra (India)
    //       </p>
    //     </div>
    //     <div>
    //       <i className="fa fa-phone"></i>
    //       <p> (+91) 99987 99987</p>
    //     </div>
    //     <div>
    //       <i className="fa fa-envelope"></i>
    //       <p>
    //         <a href="mailto:symposiumviit@gmail.com">
    //           {" "}
    //           symposiumviit@gmail.com
    //         </a>
    //       </p>
    //     </div>
    //   </div>

    //   <div className="column__footer middle"></div>

    //   <div className="column__footer right">
    //     {/* <h2> Company<span> logo</span></h2> */}
    //     <form>
    //       <div class="form-group">
    //         <label for="exampleInputEmail1">Email address</label>
    //         <input
    //           type="email"
    //           class="form-control"
    //           id="exampleInputEmail1"
    //           aria-describedby="emailHelp"
    //         />
    //         <small id="emailHelp" class="form-text text-muted">
    //           We'll never share your email with anyone else.
    //         </small>
    //       </div>
    //       <div class="form-group">
    //         <label for="exampleInputPassword1">Password</label>
    //         <input
    //           type="password"
    //           class="form-control"
    //           id="exampleInputPassword1"
    //         />
    //       </div>
    //       <div class="form-group form-check">
    //         <input
    //           type="checkbox"
    //           class="form-check-input"
    //           id="exampleCheck1"
    //         />
    //         <label class="form-check-label" for="exampleCheck1">
    //           Check me out
    //         </label>
    //       </div>
    //       <button type="submit" class="btn btn-primary">
    //         Submit
    //       </button>
    //     </form>
    //     <p className="name pl-3 pt-2"> Symposium VIIT &copy; 2020</p>
    //   </div>
    // </div>
  );
}

export default Footer;
