import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Topbar from "./Topbar/Topbar";
import Jumbotron from "./Jumbotron/Jumbotron";
import HowItWorks from "./HowItWorks/HowItWorks";
import TechnologyFreeHolidays from "./TechnologyFreeHolidays/TechnologyFreeHolidays";
import OurTeam from "./OurTeam/OurTeam";
import Footer from "./Footer/Footer";
import About from "./About/About";

function App() {
  return (
    <Router>
      <Topbar isHome="true"></Topbar>
      <Jumbotron />
      <About />
      <HowItWorks />
      <TechnologyFreeHolidays />
      <OurTeam />
      <div className="mt-5"></div>
      <Footer />
    </Router>
  );
}

export default App;
