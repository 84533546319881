import React from "react";
import "./OurTeam.css";
import OurTeamCard from "./OurTeamCard/OurTeamCard";

// import SimpleMap from './Components/SimpleMap';

function OurTeam() {
  return (
    <div className="container" id="our-team" style={{ marginTop: "24px" }}>
      <p class="h2 text-center firasans">Meet The Team</p>
      <hr />

      <div class="card-deck">
        <OurTeamCard
          name="Jay Gandhi"
          title="Founder"
          image="images/jay.png"
          desc="A travel enthusiast by birth, Jay brings his passion to curate journeys that are uniquely tailored for you, experiences crafted for your soul, moments that become lifelong memories. This is not counting the few years of his life he has spent travelling himself. A person who will stay up all night if you have an early morning flight to catch, pick a destination and let Jay  manufacture his magic. Or better still, just take a break and let him do the rest!"
        />
        <OurTeamCard
          name="Viraj Chatane"
          title="Web Developer"
          image="images/viraj.jpg"
          desc="A Developer, Engineer and Technology enthusiast brings you this website and its immersive experience. I’m Viraj, the tech-guy, who developed this website, trying to give you glimpse of luxury and ensure that no one is tracking your information through this website. LV values your privacy, that’s my promise. That’s all folks, nothing else!"
        />
      </div>
    </div>
  );
}

export default OurTeam;
