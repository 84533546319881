import React from "react";
import "./About.css";

function About() {
  return (
    <div className="skewed-bg" style={{ marginTop: "0px" }}>
      <div className="container content">
        <p class="h2 text-center firasans">
          A luxury travel specialist curating personal escapes
        </p>
        <hr />
        <div className="text-center mukta">
          Launched during the pandemic in 2021 by an avid traveller and a
          lifestyle connoisseur. LV believes luxury travel in its true sense is
          the perfect curation of your personal interests and unique tastes.
          <br />
          So, if you are a traveller in your heart, mind and soul or wish to
          take few days off for a vacay then “Discover your travel statement”
        </div>
      </div>
    </div>
  );
}

export default About;
