import { Component } from "react";
import "./TechnologyFreeHolidays.css";

class TechnologyFreeHolidays extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <div
        className="container-fluid tech-free-holidays-bg"
        id="tech-free-holidays"
        style={{ marginTop: "48px" }}
        // data-type="parallax"
        // data-speed="4"
      >
        <div className="container">
          <p class="h2 text-center firasans text-black">
            Technology Free Holidays
          </p>
          {/* <hr style={{ borderTop: "1px solid #014A73" }} /> */}
          <br />

          <div className="row mukta">
            <div className="col-sm-6 make-left">
              <span style={{ fontSize: "20px", lineHeight: "42px" }}>
                Say NO to <br />
                Algorithms AI/ML <br />
                Chatbots and <br />
                all that glam
              </span>
            </div>
            <div className="col-sm-6 text-left">
              <span>
                Holidays are meant to detox. From the stress of everyday life.
                From the constant interruption of your mobile phones as you try
                to live your life.
              </span>
              <br />
              <br />
              <span>
                Welcome to technology free holidays. Email is the highest form
                of technology we use. No pesky apps, robotic call centres, blah
                blah... you get it. Good old human interaction with an actual
                connoisseur.
              </span>
              <br />
              <br />
              <span>Give it a shot. Its free</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TechnologyFreeHolidays;
