import Carousel from "react-bootstrap/Carousel";
import React from "react";
import "./Jumbotron.css";
import { BrowserView, MobileView } from "react-device-detect";
import { NavHashLink as NavLink } from "react-router-hash-link";

const styles = {
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
  },
};

class Jumbotron extends React.Component {
  componentDidMount() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  onBtnClick() {}

  render() {
    return (
      <div id="home" className="coverview">
        <BrowserView>
          <Carousel
            fade
            indicators={false}
            className="carousel slide"
            style={styles.root}
            data-ride="carousel"
          >
            <Carousel.Item>
              <img
                src="pictures/img_tropical.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div className="black-overlay"></div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="pictures/img_tent.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div className="black-overlay"></div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="pictures/img_action.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div className="black-overlay"></div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="pictures/img_night.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div className="black-overlay"></div>
            </Carousel.Item>

            <div
              className="container intro wrapper text-center"
              style={{ position: "relative" }}
            >
              <div className="venue-details">
                <h1 style={{ textTransform: "uppercase" }}>
                  Take a break from Technology
                </h1>
                <h1 style={{ textTransform: "uppercase" }} className="venue">
                  This vacation - even while you plan
                </h1>
                <br />
                <span style={{ display: "flex", justifyContent: "center" }}>
                  <NavLink to="/#contact-us" className="btn btn-primary">
                    How to book?
                  </NavLink>
                </span>
              </div>
            </div>
          </Carousel>
        </BrowserView>
        <MobileView>
          <div className="container intro wrapper text-center">
            <div className="venue-details">
              <h1 style={{ textTransform: "uppercase" }}>
                Take a break from Technology
              </h1>
              <h1 style={{ textTransform: "uppercase" }} className="venue">
                This vacation - even while you plan
              </h1>
              <br />
              <span style={{ display: "flex", justifyContent: "center" }}>
                <button type="button" className="btn btn-outline-success">
                  How to book?
                </button>
              </span>
            </div>
          </div>
        </MobileView>
      </div>
    );
  }
}

export default Jumbotron;
