import React from "react";

const HowItWorksCard = (props) => (
  <div className="card" style={{ width: "18rem;" }}>
    <span style={{ display: "flex", justifyContent: "center" }}>
      <img
        src={props.image}
        class="card-img-top"
        alt=""
        style={{
          marginTop: "14px",
          marginBottom: "14px",
          background: "#ffffff",
          width: "64px",
        }}
      />
    </span>
    <div className="card-body mukta">
      <h5 className="card-title text-center">{props.title}</h5>
      <p className="card-text">
        <ul>
          {props.information.map((info) => {
            return <li>{info}</li>;
          })}
        </ul>
      </p>
    </div>
  </div>
);

export default HowItWorksCard;
